import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'

import './circle-test.css'

const fontLoader = new THREE.FontLoader()

const CircleTest = () => {
  const mountRef = useRef()

  useEffect(() => {
    if (!mountRef.current) return
    let rendererWidth = Math.min(window.innerWidth, 1920)
    let rendererHeight = Math.min(window.innerHeight, 1080)

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      30,
      rendererWidth / rendererHeight,
      0.1,
      1000
    )
    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.setSize(rendererWidth, rendererHeight)
    // use ref as a mount point of the Three.js scene instead of the document.body
    mountRef.current.appendChild(renderer.domElement)

    //
    //Lighting:
    //
    const ambiantLight = new THREE.AmbientLight(0xffffff, 0.3)
    scene.add(ambiantLight)
    const directionalLight = new THREE.DirectionalLight(0xffffff, 2)
    scene.add(directionalLight)
    directionalLight.position.set(5, 0, 30)
    const directionalLight2 = new THREE.PointLight(0xffffff, 0.4)
    directionalLight2.position.set(-5, -3, 5)
    scene.add(directionalLight2)

    //
    //Cursor:
    //
    let cursorAngle = 0
    function mouseMove(e) {
      const cx = rendererWidth / 2
      const cy = rendererHeight / 2
      cursorAngle =
        Math.PI * 2 - normaliseAngle(Math.atan2(e.clientY - cy, e.clientX - cx))
    }
    document.addEventListener('mousemove', mouseMove)

    const letters = [
      {
        char: 'T',
        mesh: null,
        initialRotation: [-0.1 * Math.PI, 0.2 * Math.PI, 0],
        angle: 0,
      },
      {
        char: 'E',
        mesh: null,
        initialRotation: [0.1 * Math.PI, 0 * Math.PI, -0.2 * Math.PI],
        angle: 0,
      },
      {
        char: 'A',
        mesh: null,
        initialRotation: [-0.1 * Math.PI, 0.1 * Math.PI, 0],
        angle: 0,
      },
      {
        char: 'M',
        mesh: null,
        initialRotation: [-0.2 * Math.PI, -0.2 * Math.PI, -0.2 * Math.PI],
        angle: 0,
      },
      {
        char: 'G',
        mesh: null,
        initialRotation: [-0.1 * Math.PI, -0.1 * Math.PI, -0.1 * Math.PI],
        angle: 0,
      },
      {
        char: 'E',
        mesh: null,
        initialRotation: [0.1 * Math.PI, -0.2 * Math.PI, 0 * Math.PI],
        angle: 0,
      },
      {
        char: 'E',
        mesh: null,
        initialRotation: [0.1 * Math.PI, 0 * Math.PI, -0.2 * Math.PI],
        angle: 0,
      },
      {
        char: 'K',
        mesh: null,
        initialRotation: [-0.2 * Math.PI, 0, 0.2 * Math.PI],
        angle: 0,
      },
    ]
    let angleOffset = 0
    const step = (2 * Math.PI) / letters.length
    const radius = 2.3
    function normaliseAngle(angle) {
      angle = mod(angle, Math.PI * 2)
      if (angle < 0) {
        angle += Math.PI * 2
      }
      return angle
    }
    function mod(a, n) {
      return ((a % n) + n) % n
    }
    function calculateLetterPositions() {
      angleOffset = normaliseAngle(angleOffset)
      let angle = angleOffset

      for (let letter of letters) {
        angle = normaliseAngle(angle)
        if (!letter.mesh) continue

        var x = 4 / 2 + radius * Math.cos(angle)
        var y = 0 / 2 + radius * Math.sin(angle)
        letter.angle = angle

        let d = letter.angle - cursorAngle
        d = Math.abs(mod(d + Math.PI, Math.PI * 2) - Math.PI)
        let closeness = (Math.PI - d) / Math.PI
        closeness = Math.min(Math.pow(closeness, 3), 1)

        letter.rotation = [
          (1 - closeness) * letter.initialRotation[0],
          (1 - closeness) * letter.initialRotation[1],
          (1 - closeness) * letter.initialRotation[2],
        ]

        letter.mesh.position.set(x, y, closeness)
        angle -= step
      }
    }
    fontLoader.load('fonts/helvetiker_regular.typeface.json', function(font) {
      for (let letter of letters) {
        const text = new THREE.TextGeometry(letter.char, {
          font,
          size: 1,
          height: 0.5,
          curveSegments: 12,
        })
        var textMaterial = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          roughness: 0,
        })
        var textMesh = new THREE.Mesh(text, textMaterial)
        textMesh.geometry.center()

        letter.mesh = textMesh
        letter.mesh.rotation.set(...letter.initialRotation)
        letter.rotation = [...letter.initialRotation]
        scene.add(textMesh)
      }
      calculateLetterPositions()
    })

    function lerp(a, b, t) {
      return (1 - t) * a + t * b
    }

    var animate = function() {
      window.requestAnimationFrame(animate)

      // Rotate circle
      angleOffset -= 0.002
      calculateLetterPositions()

      for (let letter of letters) {
        if (!letter.mesh) continue
        const time = 0.1
        const updatedRotation = [
          lerp(letter.mesh.rotation.x, letter.rotation[0], time),
          lerp(letter.mesh.rotation.y, letter.rotation[1], time),
          lerp(letter.mesh.rotation.z, letter.rotation[2], time),
        ]

        letter.mesh.rotation.set(...updatedRotation)
      }

      renderer.render(scene, camera)
    }
    resize()
    animate()

    function resize() {
      rendererWidth = Math.min(window.innerWidth, 1920)
      rendererHeight = Math.min(window.innerHeight, 1080)
      camera.aspect = rendererWidth / rendererHeight
      camera.position.z =
        rendererWidth < 650 ? 40 : rendererWidth < 800 ? 22 : 14
      camera.updateProjectionMatrix()
      renderer.setSize(rendererWidth, rendererHeight)
    }

    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('mousemove', mouseMove)
    }
  }, [])

  return (
    <div style={{ maxWidth: '100vw', maxHeight: '100vh' }} ref={mountRef} />
  )
}

export default CircleTest
