import React, { Component } from 'react'

import Layout from '../components/layout'
import CircleTest from '../components/CircleTest'

class Page extends Component {
  render() {
    return (
      <Layout>
        <CircleTest />
      </Layout>
    )
  }
}

export default Page
